@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $grey;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
	height: 100vh;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

main {
	div {
		height: 100vh;
		position: relative;
		width: 100%;

		a {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.bg-video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				overflow: hidden;
			}
		}
	}

	.mobile {
		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	.desktop {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}